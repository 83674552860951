import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { CommonServices } from 'src/app/services/comon.service';


@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(
        private service: CommonServices,
    ) { }



    public async Register(body): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/Member`;
            //     let body = [{
            //         "columnName": "pageid",
            //         "columnValue": '7'
            //     },{
            //         "columnName": "sectionid",
            //         "columnValue": '36'
            //     }
            //   ]
                this.service.Http.post(url, body).pipe(
                    // tap(d => console.log('test',d)),
                   // map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }


    public async UserLogin(body): Promise<any> {
      try {
          return new Promise((resolve, reject) => {
              const url = `${this.service.serviceUrl}/Member/filter?andOr=AND&page=1&itemsPerPage=100`;
              this.service.Http.post(url, body).pipe(
                  // tap(d => console.log('test',d)),
                 // map((d: any) => (d.code === 1 ? d.document.records : false))
              ).subscribe((r: any) => resolve(r), reject);
          });
      } catch (error) {
          console.log(error);
      }
  }


  public async UserReset(body): Promise<any> {
    try {
        return new Promise((resolve, reject) => {
            const url = `${this.service.serviceUrl}/SP/UserOperations`;
            this.service.Http.post(url, body).pipe(
                // tap(d => console.log('test',d)),
               // map((d: any) => (d.code === 1 ? d.document.records : false))
            ).subscribe((r: any) => resolve(r), reject);
        });
    } catch (error) {
        console.log(error);
    }
}


public async Usersetpassword(body): Promise<any> {
  try {
      return new Promise((resolve, reject) => {
          const url = `${this.service.serviceUrl}/SP/UserOperations`;
          this.service.Http.post(url, body).pipe(
              // tap(d => console.log('test',d)),
             // map((d: any) => (d.code === 1 ? d.document.records : false))
          ).subscribe((r: any) => resolve(r), reject);
      });
  } catch (error) {
      console.log(error);
  }
}


public async UsersActivity(body): Promise<any> {
  try {
      return new Promise((resolve, reject) => {
          const url = `${this.service.serviceUrl}/Userloginactivity`;
          this.service.Http.post(url, body).pipe(
          ).subscribe((r: any) => resolve(r), reject);
      });
  } catch (error) {
      console.log(error);
  }
}




}
