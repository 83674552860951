import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { of } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CommonServices {
  public serviceUrl = environment.ServiceUrl;
  public imgurl = environment.Imgurl;
  isSpinnerVisible: boolean;
  YoutubeVideo: SafeResourceUrl;
  //public WebApi = 'https://us-central1-p2pchealth.cloudfunctions.net/api';
  //public sitepath = 'https://www.pbsbahamas.com';
  public MenuStatus = new BehaviorSubject<boolean>(true);
  public renderer: Renderer2;
//   testimonials = [
//     {
//       name: 'DR. CJ BASDEN',
//       image: '/assets/images/jpg/CJ-Basden-sm.jpg',
//       quote: 'PBS has been a very valuable asset to The Medi Center as it relates to management of our computer systems.'
//     },
//     {
//       name: 'MRS.C. ADDERLEY',
//       image: '/assets/images/jpg/coralie-adderley.jpg',
//       quote: 'The Kidney Center is grateful to PBS for it’s level of professionalism and IT partner.'
//     }
//   ];
  isBrowser: any;
  constructor(
    rendererFactory: RendererFactory2,
    private meta: Meta,
    private title: Title,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public router: Router,
    config: NgbModalConfig,
    public Http: HttpClient,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    config.backdrop = 'static';
    config.keyboard = false;
    this.isBrowser = isPlatformBrowser(platformId);
  }
  generateTags(tags: any) {
    tags = {
      title: 'Bcop',
      description: 'Bcop',
      keywords: 'Bcop',
      // image: 'https://www.p2pchealth.com/assets/images/png/logoo.png',
      path: '',
      ...tags
    };
    this.title.setTitle(tags.title + ' | Bcop');
    this.meta.updateTag({ name: 'Description', content: tags.description });
    this.meta.updateTag({ name: 'Keywords', content: tags.keywords });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@pbsbahamas' });
    this.meta.updateTag({ name: 'twitter:title', content: tags.title});
    this.meta.updateTag({ name: 'twitter:description', content: tags.description });
    this.meta.updateTag({ name: 'twitter:image', content: tags.image });
    // this.meta.updateTag({ property: 'fb:app_id', content: '182752693091917' });
    this.meta.updateTag({ property: 'og:type', content: 'product' });
    this.meta.updateTag({ property: 'og:site_name', content: 'pbsbahamas' });
    this.meta.updateTag({ property: 'og:title', content: tags.title });
    this.meta.updateTag({ property: 'og:description', content: tags.description });
    this.meta.updateTag({ property: 'og:image', content: tags.image });
    //this.meta.updateTag({ property: 'og:url', content: this.sitepath + '/' + tags.path });
  }
  playYoutube(content, video) {
    this.modalService.open(content, { centered: true, windowClass: 'MyYoutube', size: 'lg' });
    this.YoutubeVideo = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video + '?autoplay=1&cc_load_policy=1');
  }
  public GotoURL(url: string) {
    this.router.navigate([url]);
  }
  public GoTo($element: any): void {
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
//   public getTestimonials(): any {
//     return this.testimonials;
//   }
  public AlphabetsOnly(event: any) {
    const charCode = event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode === 8 || charCode === 32) {
      event.target.value = event.target.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g, '');
      return true;
    } else {
      return false;
    }
  }
  public numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public SwalSuccess(msg: string, heading = 'Success!') {
    Swal.fire({
      title: heading,
      text: msg,
      icon: 'success',
      confirmButtonColor: '#0f4a21'
    })
  }
  public SwalWarning(msg: any, heading = 'Warning') {
    Swal.fire(msg, heading, 'warning');
  }
  public SwalError(msg: any, heading = 'Error') {
    Swal.fire(msg, heading, 'error');
  }


  public async Token(): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceUrl}/Token`;
        const body = {
          "username": "PBSB_admin",
          "password": "PBSB_admin123"
        };
        return this.Http.post<any>(url, body).pipe(
          // tap(d => console.log('test', d)),
          map(d => (d.code === 1) ? d.document.accessToken : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }


}