import { Component, OnInit } from '@angular/core';
declare var $: any;
declare var $;
import { Router } from '@angular/router';
import * as publicIp from 'public-ip';
import { LoginService } from 'src/app/pages/login/login.service';
@Component({
  selector: 'com-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  youtube = 'https://www.youtube.com/watch?v=OnMgRRr54MA'
  loginid: string;
  userName: string;
  Login_SignUp = false;
  IpAddress: string;
  constructor(public router: Router, private Loginservice: LoginService) {
    document.addEventListener("DOMContentLoaded", function () {
      window.addEventListener('scroll', function () {
        if (window.scrollY > 50) {
          document.getElementById('navbar_top').classList.add('fixed-top');

        } else {
          document.getElementById('navbar_top').classList.remove('fixed-top');
          // remove padding top from body
          document.body.style.paddingTop = '0';
        }
      });
    });
  }

  ngOnInit(): void {
    publicIp.v4().then(ip => {
      this.IpAddress = ip;
    });
    this.bindstickynav();
    this.loginid = localStorage.getItem('loginid');
    this.userName = localStorage.getItem('userName');
  }
  bindstickynav() {

    $(window).scroll(function () {
      if ($(this).scrollTop() > 60) {
        $('.header-sticky').addClass('sticky')
      } else {
        $('.header-sticky').removeClass('sticky')
      }
    });
    $(function () {
      var navbarNav = $("#navbarNav");
      navbarNav.on("click", "ul li a", null, function () {
        navbarNav.collapse('hide');
      });
    });

  }


  logout() {
    const body = {
      "activityID": 0,
      "memberID": localStorage.getItem("loginid"),
      "activityDateTime": new Date().toISOString(),
      "activityType": "Logout",
      "ipAddress": this.IpAddress
    }
    this.Loginservice.UsersActivity(body).then(r => {
      localStorage.removeItem("loginid");
      localStorage.removeItem("loginId");
      localStorage.removeItem("userName");
     // setTimeout(() => {
      this.router.navigateByUrl("login")

     // }, 100);
    })

  }
  navigation(id) {
    localStorage.setItem('id', id)

  }
  onClick() {
    this.Login_SignUp = !this.Login_SignUp;
  }
}
