<!-- Header -->

<div class="top d-none d-sm-block">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="flat-address">
          <div class="social-links">
            <!-- <a href={{youtube}} target='_blank'>
              <i class="fa fa-youtube"></i>
            </a> -->
            <!-- <a href="#">
                                  <i class="fa fa-facebook-official"></i>
                              </a>
                              <a href="#">
                                  <i class="fa fa-google-plus"></i>
                              </a> -->
          </div>
          <div class="custom-info" *ngIf="!loginid || router.url==='/login'">
            <span>Have any questions?</span>
            <i class="fa fa-envelope"></i> PSEREADI@case.edu
          </div>
          <div class="custom-info" *ngIf="loginid && router.url!=='/login'">
            <span>WELCOME </span>
            <i class="fa fa-user"></i>{{userName}}
          </div>

        </div><!-- /.flat-address -->
      </div><!-- /.col-md-8 -->
      <div class="col-md-4 text-right">
        <div class="top-navigator">
          <ul>

            <!-- <li><a href="#">Case Studies</a></li> -->
            <!-- <ul>

                                      <li><a href="portfolio-single.html">Login</a></li>
                                      <li><a href="portfolio-single-v2.html">Sign Up</a></li>
                                  </ul> -->

            <li><a routerLink="/login" *ngIf="!loginid">Login </a></li>
            <li><a routerLink="/signup" *ngIf="!loginid">Sign Up</a></li>

            <li><a *ngIf="loginid" routerLink='/dashboard'>My Dashboard </a></li>
            <li><a  *ngIf="loginid" (click)='logout()' style="cursor: pointer;">Logout</a></li>


          </ul>
        </div><!-- /.top-navigator -->
      </div><!-- /.col-md-4 -->
    </div><!-- /.row -->
  </div><!-- /.container -->
</div><!-- /.top -->

<header class="header header-v3 clearfix">
  <div class="header-wrap clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="flat-logo">
            <div id="logo" class="logo">
              <a routerLink=''>
                <img src="assets/images/logo.png" alt="images">
              </a>
            </div>
          </div>
          </div>
          <div class="col-md-5">
            <!-- <div class="accesstxt">
        To access your  previous reports from our archived website, <br>
        please visit <a href="https://archive.psereadi.org/" style="color:blue !important"target="_blank">www.archive.psereadi.org</a> and download old reports. <br>
        Create a new account here today!
            </div> -->
          </div>

<div class="col-md-3">
          <div class="flat-header-information d-none d-sm-none d-md-block">
            <div class="header-information">
              <div class="informaiton-text">
                <a class="button sm " *ngIf='!loginid' routerLink="/login">Take Assessment</a>
                <a class="button sm " *ngIf='loginid'  routerLink="/my-account">Take Assessment</a>
              </div>
            </div>

          </div><!-- /.flat-header-infomation -->
        </div><!-- /.flat-wrapper -->
      </div><!-- /.row -->
    </div><!-- /.container -->
  </div><!-- /.header-wrap -->
</header>








 <!-- New Menu 1 -->

 <header id="header" class="header header-v3 clearfix"  style="display: none;">
  <div class="header-wrap style header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-md-12">


          <nav class="navbar navbar-expand-lg navbar-dark sticky-top">
            <div class="container-fluid">

              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">

                  <li class="nav-item">
                    <a class="nav-link active" routerLink='/'> Home </a>
                  </li>

                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" *ngIf='loginid' routerLink='/my-account' id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   Assess
                    </a>
                    <a class="nav-link dropdown-toggle"  *ngIf='!loginid' routerLink='/login' id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Assess
                       </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
                      <a class="dropdown-item" routerLink='/psetraining'> PSE AREAS </a>
                    </div>
                  </li>

                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" routerLink="/pse-plan" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   Plan
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
                      <a class="dropdown-item" routerLink="/Know-Your-Community"> Know your community</a>
                      <a class="dropdown-item" routerLink="/training-webinars"> Training Webinars </a>
                      <a class="dropdown-item" routerLink="/pse-resources"> Resource Bank </a>
                    </div>
                  </li>

                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" routerLink="/pse-act" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   Act
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
                      <a class="dropdown-item" routerLink="/pse-action"> PSEs in action  </a>
                      <a class="dropdown-item" routerLink="/act-next-step"> Next Steps </a>
                    </div>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" routerLink="/about"> About Us </a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link"  routerLink="/faq"> Faq's </a>
                  </li>


                </ul>
              </div>
            </div>
          </nav>


        </div><!-- /.flat-wrapper -->
      </div><!-- /.row -->
    </div><!-- /.container -->
  </div><!-- /.header-wrap -->
</header><!-- /.header -->



<!-- // old menu -->

<header id="header" class="header header-v3 clearfix ">
  <div class="header-wrap style header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <nav class="navbar navbar-expand-lg navbar-dark sticky-top  " id="navbar_top">

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item ">
                  <a data-toggle="collapse" data-target=".navbar-collapse.show" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact:
                                  true}" class="nav-link" routerLink='' (click)='navigation("1")'>Home <span
                      class="sr-only">(current)</span></a>
                </li>

                <!--  new assess -->
                <li class="nav-item dropdown">
                  <a   *ngIf='loginid' routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                  true}" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    routerLink='/my-account'>Assess</a>

                    <a  *ngIf='!loginid' routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                      true}" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
        routerLink='/login'>Assess</a>


                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <!-- <a data-toggle="collapse" data-target=".navbar-collapse.show" class="dropdown-item"
                      routerLink='/pse-area'>PSE Areas Info</a> -->
                    <a data-toggle="collapse" data-target=".navbar-collapse.show" class="dropdown-item"
                      routerLink='/psetraining'>PSE Areas</a>





                  </div>
                </li>
                <!--
                              new assess -->
                <!--  new plan-->
                <li class="nav-item dropdown">
                  <a routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                  true}" class="nav-link dropdown-toggle"  id="navbarDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" routerLink="/pse-plan">Plan</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a data-toggle="collapse" data-target=".navbar-collapse.show" class="dropdown-item"
                      routerLink="/Know-Your-Community">Know your community</a>
                    <a data-toggle="collapse" data-target=".navbar-collapse.show" class="dropdown-item"
                      routerLink="/training-webinars">Training Webinars</a>
                    <a data-toggle="collapse" data-target=".navbar-collapse.show" class="dropdown-item"
                      routerLink="/pse-resources">Resource Bank</a>
                  </div>
                </li>



                <li class="nav-item dropdown">
                  <a routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                  true}" class="nav-link dropdown-toggle"  id="navbarDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" routerLink="/pse-act">Act</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">

                    <a data-toggle="collapse" data-target=".navbar-collapse.show" class="dropdown-item"
                      routerLink="/pse-action">PSEs in action </a>
                    <a data-toggle="collapse" data-target=".navbar-collapse.show" class="dropdown-item"
                      routerLink="/act-next-step">Next Steps</a>

                  </div>
                </li>

                <li class="nav-item ">
                  <a data-toggle="collapse" data-target=".navbar-collapse.show" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact:
                                  true}" class="nav-link" routerLink='/about' (click)='navigation("4")'>About Us <span
                      class="sr-only">(current)</span></a>
                </li>


                <li class="nav-item">
                  <a data-toggle="collapse" data-target=".navbar-collapse.show" class="nav-link" data-toggle="collapse"
                    routerLink="/faq" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                  true}">Faq's</a>
                </li>



              </ul>
            </div>
          </nav>



        </div><!-- /.flat-wrapper -->
      </div><!-- /.row -->
    </div><!-- /.container -->
  </div><!-- /.header-wrap -->
</header><!-- /.header -->
