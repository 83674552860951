   <!-- Footer -->

   <div class="container pt-5" *ngIf="router.url === '/'|| router.url === '/about'">
      <!-- <div class="row">
      <div class="col-md-12" *ngIf="router.url === '/'">
          <div class="flat-title-button" *ngIf='partners' >
              <h3 class="title"><i class="fa fa-newspaper-o"></i>{{partners[0].name}}</h3>

          </div>
      </div></div> -->
 <div class="row" >
  <div class="col-md-12 text-center" *ngIf='partners'>
    <p  [innerHtml]='partners[0].content_full' *ngIf="router.url === '/'"> </p></div>

<div class="col-md-12"> <div class="clients-image style1 clearfix row">
    <ng-container *ngFor='let L of Logos'>
  <div class="col-md-4 text-center mt-3">
      <div class="item-img ciborder">
          <img [src]="Service.imgurl+L.image_url"alt="images" class="img-fluid">
      </div>

  </div>
</ng-container>

</div></div>

</div>

   </div>
   <footer class="footer">



    <div class="footer-content">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <!-- <div class="social-links">
                        <a href="#" target="_blank">
                            <i class="fa fa-youtube"></i>
                        </a>

                    </div> -->
                </div><!-- /.col-md-12 -->
                <div class="col-md-12">
                    <div class="copyright">
                        <div class="copyright-content">
                          <!-- href="../../../assets/download/2019_Lee et al.pdf -->
                          Copyright © 2020 Case Western Reserve University. All rights reserved.<a href="../../../assets/download/BCOP_PSE_READI_TERMS_OF_USE.pdf" target="_blank"> Terms & Conditions</a>.
                        </div>
                    </div>
                </div><!-- /.col-md-12 -->
            </div><!-- /.row -->
        </div><!-- /.container -->
    </div><!-- /.footer-content -->
</footer>
