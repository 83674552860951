import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BodyComponent } from './body/body.component';
import { AuthGuard } from 'src/AuthGurd';
import { HomeComponent } from './pages/home/home.component';
import { RecommendationComponent } from './account/recommendation/recommendation.component';
// canActivate: [AuthGuard],
const routes: Routes = [
  {
   path: '',
   component:BodyComponent,
    children: [
      { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
      { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
      { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
      { path: 'psetraining', loadChildren: () => import('./pages/psetraining/psetraining.module').then(m => m.PsetrainingModule) },
      { path: 'pse-action', loadChildren: () => import('./pages/pse-action/pse-action.module').then(m => m.PseActionModule) },
      { path: 'pse-resources', loadChildren: () => import('./pages/pse-resources/pse-resources.module').then(m => m.PseResourcesModule) },
      { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
      //{ path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
      { path: 'signup', loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule) },
      { path: 'farmer-market', loadChildren: () => import('./pages/farmer-market/farmer-market.module').then(m => m.FarmerMarketModule) },
  { path: 'healthy-eating-childcare', loadChildren: () => import('./pages/healthy-eating-childcare/healthy-eating-childcare.module').then(m => m.HealthyEatingChildcareModule) },
  { path: 'hfr', loadChildren: () => import('./pages/hfr/hfr.module').then(m => m.HfrModule) },
  { path: 'f2s', loadChildren: () => import('./pages/f2s/f2s.module').then(m => m.F2sModule) },
  { path: 'leaders', loadChildren: () => import('./pages/leaders/leaders.module').then(m => m.LeadersModule) },
  { path: 'history', loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryModule) },
  { path: 'Potential-Teams-Members', loadChildren: () => import('./pages/potential-teams-members/potential-teams-members.module').then(m => m.PotentialTeamsMembersModule) },
  { path: 'decision-Tree', loadChildren: () => import('./pages/decision-tree/decision-tree.module').then(m => m.DecisionTreeModule) },
  { path: 'how-to-take-assessment', loadChildren: () => import('./pages/how-to-take-assessment/how-to-take-assessment.module').then(m => m.HowToTakeAssessmentModule) },
  { path: 'support-tool', loadChildren: () => import('./pages/support-tool/support-tool.module').then(m => m.SupportToolModule), },
  { path: 'resetPassword', loadChildren: () => import('./forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule), },
  //{ path: 'my-account', loadChildren: () => import('./account/my-account/my-account.module').then(m => m.MyAccountModule), },
  { path: 'survery-service-area', loadChildren: () => import('./account/survey-service-area/survey-service-area.module').then(m => m.SurveyServiceAreaModule) },
  { path: 'survery',  loadChildren: () => import('./account/survey/survey.module').then(m => m.SurveyModule) },
  { path: 'my-profile', loadChildren: () => import('./account/my-profile/my-profile.module').then(m => m.MyProfileModule) },
  { path: 'bookmark', loadChildren: () => import('./account/bookmark/bookmark.module').then(m => m.BookmarkModule) },
  { path: 'pse-area', loadChildren: () => import('./pages/pse-area/pse-area.module').then(m => m.PseAreaModule) },
  { path: 'pse-plan', loadChildren: () => import('./pages/pse-plan/pse-plan.module').then(m => m.PsePlanModule) },
  { path: 'training-webinars', loadChildren: () => import('./pages/training-webinars/training-webinars.module').then(m => m.TrainingWebinarsModule) },
  { path: 'Know-Your-Community', loadChildren: () => import('./pages/know-your-community/know-your-community.module').then(m => m.KnowYourCommunityModule) },
  { path: 'act-next-step', loadChildren: () => import('./pages/act-next-step/act-next-step.module').then(m => m.ActNextStepModule) },
  { path: 'pse-act', loadChildren: () => import('./pages/pse-act/pse-act.module').then(m => m.PseActModule) },
  { path: 'dashboard', loadChildren: () => import('./account/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'Recommendation', loadChildren: () => import('./account/recommendation/recommendation.module').then(m => m.RecommendationModule) },
  { path: 'retake-individual-assessment', loadChildren: () => import('./account/retake-individual-assessment/retake-individual-assessment.module').then(m => m.RetakeIndividualAssessmentModule) },
]
  },




    { path: 'my-account', loadChildren: () => import('./account/my-account/my-account.module').then(m => m.MyAccountModule), },
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
   // { path: 'dashboard', loadChildren: () => import('./account/dashboard/dashboard.module').then(m => m.DashboardModule) },

    // path: '',
    // component: BodyComponent,
    //  canActivate: [AuthGuard],
    //  children: []






];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
