import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BodyComponent } from './body/body.component';
import { HeaderModule } from './module/header/header.module';
import { FooterModule } from './module/footer/footer.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenIntercepterService } from './token-intercepter.service';
import { FHHFModule } from './modules/fhhf/fhhf.module';
import { RecommendationComponent } from './account/recommendation/recommendation.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { BcopService } from './bcop.service';
import { AuthService } from './auth.service';
import { BodyModule } from './body/body.module';


@NgModule({
  declarations: [
    AppComponent,
   // BodyComponent,
    //ForgotpasswordComponent,
   // RecommendationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
   // HeaderModule,
   // FooterModule,
    // BodyModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true
    }),
    NgbModule
  ],
  providers: [AuthService,{provide:HTTP_INTERCEPTORS,useClass:TokenIntercepterService,multi:true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
