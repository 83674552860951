import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {

  constructor(private http:HttpClient) { }

  ngOnInit(): void {


    this.token();
  }
  token(){

    let body={
      "username": "Bcop_admin",
      "password": "Bcop_admin123"
    }
    return this.http.post<any>(`${environment.ServiceUrl}/Token`,body).subscribe(r=>{
      console.log(r)
      localStorage.setItem('token',r.document.accessToken)
    })
  }
}
