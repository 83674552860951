import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { CommonServices } from 'src/app/services/comon.service';

@Injectable({
    providedIn: 'root'
})
export class FooterService {

    constructor(
        private service: CommonServices,
    ) { }

    // resolve(route: ActivatedRouteSnapshot) {
    //     const bsValue = new Date();
    //     let join = forkJoin([
    //         this.service.Token()

    //     ]).pipe(map((r) => {
    //         return {
    //             token: r[0]

    //         };
    //     }));
    //     return join;
    // }
    
    public async Get_Footer(): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/CMS_Containers/filter?andOr=AND&page=1&itemsPerPage=100`;
                let body = [{
                    "columnName": "pageid",
                    "columnValue": '1'
                },{
                    "columnName": "sectionid",
                    "columnValue": '31'
                }
              ]
                this.service.Http.post(url, body).pipe(
                    // tap(d => console.log('test',d)),
                    map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }

    public async Get_Logos(): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/CMS_Containers/filter?andOr=AND&page=1&itemsPerPage=100`;
                let body = [{
                    "columnName": "pageid",
                    "columnValue": '1'
                },{
                    "columnName": "sectionid",
                    "columnValue": '32'
                }
              ]
                this.service.Http.post(url, body).pipe(
                    // tap(d => console.log('test',d)),
                    map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }


   

}