import { Component, OnInit } from '@angular/core';
import { CommonServices } from 'src/app/services/comon.service';
import { FooterService } from './footer.service';
import { Router } from '@angular/router';
@Component({
  selector: 'com-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  patner_paragraph: any;
  partners: any;
  Logos: any;
  home: string;

  constructor(public router:Router ,private footerservice:FooterService,public Service: CommonServices) { }

  ngOnInit(): void {

    if(!localStorage.getItem('token')){

  }
else{


    this.footerservice.Get_Footer().then((r: any) => {
      console.log(r)
      if(r){
        this.partners= r;
      }

    })

    this.footerservice.Get_Logos().then((r: any) => {
      console.log(r)
      if(r){
        this.Logos= r;
      }
    })
  }
  }
  }


