export const environment = {
  production: false,
  ServiceUrl: 'https://api.psereadi.org/v1/api',
//  ServiceUrl: 'https://bcopapi.meanhost.in/v1/api',
//  Imgurl:'https://bcopadmin.meanhost.in/files/'
 Imgurl:'https://admin.psereadi.org/files/'
};

//Imgurl:'https://admin.psereadi.org/files/' main
// ServiceUrl: 'https://bcopapi.meanhost.in/v1/api',
// //ServiceUrl: 'https://bcopapidev.meanhost.in/v1/api',
// Imgurl:'https://bcopadmin.meanhost.in/files/'
