import { Injectable,Injector,NgZone } from '@angular/core';
//import { HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
//import { BcopService } from './bcop.service';
//import { HttpClient } from '@angular/common/http';


import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class TokenIntercepterService implements HttpInterceptor {

  constructor(private inject:Injector,private auth:AuthService,private zone:NgZone,private toast: ToastrService, private router: Router) {
   // private ser:BcopService,private http:HttpClient
  }

  intercept(req,next){

    let authService=this.inject.get(AuthService);
let tokenizedReq= req.clone({
  setHeaders:{
    Authorization:`Bearer ${authService.getToken()}`
    //${this.ser.get_token()}
  }
})
//return next.handle(tokenizedReq)
return next.handle(tokenizedReq).pipe(
  catchError(err => {
    this.zone.run(() => {
      this.toast.error('Session Expired', 'Error!');
      // this.router.navigate(['/']);
      // localStorage.removeItem("token");
    })
    return throwError(err);
  }));
  }











}



