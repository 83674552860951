import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  cachedRequests: Array<HttpRequest<any>> = [];
  constructor() {
   }

   getToken(){
    console.log("first1")
    return localStorage.getItem('token')
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }
public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }
}
